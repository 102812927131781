'use client';
import {
  mixpanelBtnClick,
  mixpanelLinkClick,
} from '@/lib/mixpanel/services/client';
import {
  getArticleUrl,
  getFullUrl,
  getCategoryUrl,
  getProductUrl,
  getHomePageUrl,
} from '@/lib/pathUtils';
import Link from 'next/link';
import { MenuItemLink } from '@/types/types';
import { useCallback } from 'react';
import { userClickSSULinkAtom } from '@/store/ssuStore';
import { useSetAtom } from 'jotai';
import { useStatsigClient } from '@statsig/react-bindings';

interface Props {
  ref?: React.RefObject<HTMLAnchorElement>;
  link: MenuItemLink;
  target?: string;
  children?: React.ReactNode;
  onMouseEnter?: (e: React.MouseEvent<HTMLAnchorElement>) => void;
  onClick?: (e: React.MouseEvent<HTMLAnchorElement>) => void;
  className?: string;
  cardTitle?: string;
  rel?: string;
  'aria-label'?: string;
}

export default function IKLink(props: Props) {
  const isPreview = process.env.NEXT_PUBLIC_VERCEL_ENV === 'preview';
  const isProduction = process.env.NEXT_PUBLIC_VERCEL_ENV === 'production';
  const { link, children, className, onClick, cardTitle, target, rel } = props;
  const setUserClickSSULink = useSetAtom(userClickSSULinkAtom);
  const { logEvent } = useStatsigClient();

  const onButtonClick = (btn: HTMLAnchorElement, cardTitle: string) => {
    mixpanelBtnClick(btn, cardTitle);

    logEvent(
      'button_click',
      btn?.innerText ?? btn?.getAttribute('aria-label'),
      {
        page_url: window.location.origin + window.location.pathname,
        page_title: document.title,
        card_title: cardTitle,
        //@ts-ignore
        button_url: btn.href?.replace('?hsLang=en', ''),
      }
    );
  };

  const onLinkClick = (btn: HTMLAnchorElement, cardTitle: string) => {
    mixpanelLinkClick(btn, cardTitle);

    logEvent('link_click', btn?.innerText ?? btn?.getAttribute('aria-label'), {
      page_url: window.location.origin + window.location.pathname,
      page_title: document.title,
      card_title: cardTitle,
      link_label: btn?.innerText,
      //@ts-ignore
      link_url: btn.href?.replace('?hsLang=en', ''),
    });
  };

  /*
   * @param link MenuItemLink
   * @returns Formatted URL using ENV variable
   */
  const formattedUrl = (props: MenuItemLink) => {
    let formattedUrl: string;

    switch (props.document) {
      case 'home':
        formattedUrl = getHomePageUrl();
        break;
      case 'page':
        formattedUrl = getFullUrl(link?.url);
        break;
      case 'articles':
        formattedUrl = getArticleUrl(link?.url);
        break;
      case 'product':
        if (link?.category) {
          formattedUrl = getProductUrl(link?.category, link?.url);
        } else {
          formattedUrl = getProductUrl('products', link?.url);
        }
        break;
      case 'category':
        formattedUrl = getCategoryUrl(link?.url);
        break;
      default:
        formattedUrl = link?.url;
        break;
    }

    if (link?.anchor) {
      // Check if the URL already has an anchor
      if (formattedUrl.includes('#') && formattedUrl.includes(link?.anchor)) {
        return formattedUrl;
      } else {
        formattedUrl += `${link?.anchor}`;
      }
    }

    return formattedUrl;
  };

  /**
   *
   * @param link MenuItemLink
   * @returns Unified Auth URL
   */
  const formattedAuthUrl = (link: MenuItemLink) => {
    let authDomain;
    let appCode;

    const channel = 'Web';
    const callbackUrl = link?.callbackUrl;
    const siteUrl = getHomePageUrl(true);
    const redirectUrl = formattedUrl(link);

    if (
      callbackUrl?.includes('dashboard.ikhokha') ||
      redirectUrl?.includes('dashboard.ikhokha')
    ) {
      authDomain = 'https://auth.ikhokha.com/signup';
    } else if (isProduction) {
      authDomain = 'https://auth.ikhokha.com/signup';
    } else if (isPreview) {
      authDomain = 'https://auth.ikhokha.red/signup';
    } else {
      authDomain = 'https://auth.ikhokha.red/signup';
    }

    if (
      callbackUrl?.includes('dashboard.ikhokha') ||
      redirectUrl?.includes('dashboard.ikhokha')
    ) {
      appCode = 'merchant-dashboard-client';
    } else {
      appCode = 'ik-website';
    }

    if (redirectUrl && callbackUrl)
      return `${authDomain}?appCode=${appCode}&channel=${channel}&redirectUri=${encodeURIComponent(redirectUrl)}&callbackUrl=${encodeURIComponent(callbackUrl)}`;
    else
      return `${authDomain}?appCode=${appCode}&channel=${channel}&redirectUri=${encodeURIComponent(`${siteUrl}/signup-thank-you`)}&callbackUrl=${encodeURIComponent(`${siteUrl}/auth/callback`)}`;
  };

  const handleSetLocalStorageFlag = useCallback(() => {
    if (link?.linkType === 'ssu') {
      setUserClickSSULink(true);
    }
  }, [link?.linkType, setUserClickSSULink]);

  /* Internal links */
  const handleButtonClick = useCallback(
    (e: React.MouseEvent<HTMLAnchorElement>) => {
      onButtonClick(e.currentTarget, cardTitle ?? 'No card title');
      if (onClick) {
        onClick(e);
      }
    },
    [onClick, cardTitle]
  );

  /* External links */
  const handleLinkClick = useCallback(
    (e: React.MouseEvent<HTMLAnchorElement>) => {
      onLinkClick(e.currentTarget, cardTitle ?? 'No card title');
      if (onClick) {
        onClick(e);
      }
    },
    [onClick, cardTitle]
  );

  return (
    <>
      {link?.linkType === 'ssu' ? (
        <>
          <Link
            ref={props.ref}
            aria-label={props['aria-label']}
            target={target ? target : link?.newWindow ? '_blank' : undefined}
            className={` ${className} cursor-pointer`}
            href={link && link?.url ? formattedAuthUrl(link) : getHomePageUrl()}
            onMouseEnter={props.onMouseEnter}
            onClick={(e) => {
              handleLinkClick(e);
              handleSetLocalStorageFlag();
            }}
            rel={link?.noIndex ? 'noindex nofollow' : rel}
          >
            {children}
          </Link>
        </>
      ) : (
        <>
          {link?.type === 'internal' && (
            <>
              <Link
                ref={props.ref}
                aria-label={props['aria-label']}
                target={
                  target ? target : link?.newWindow ? '_blank' : undefined
                }
                className={` ${className} cursor-pointer`}
                href={link && link?.url ? formattedUrl(link) : getHomePageUrl()}
                onMouseEnter={props.onMouseEnter}
                onClick={handleButtonClick}
                rel={link?.noIndex ? 'noindex nofollow' : rel}
              >
                {children}
              </Link>
            </>
          )}
          {(link?.type === 'external' || link?.type === undefined) && (
            <>
              <a
                aria-label={props['aria-label']}
                className={className}
                href={link?.url}
                target={
                  target ? target : link?.newWindow ? '_blank' : undefined
                }
                onClick={handleLinkClick}
                rel={link?.noIndex ? 'noindex nofollow' : rel}
              >
                {children}
              </a>
            </>
          )}
        </>
      )}
    </>
  );
}
